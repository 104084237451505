import React from 'react';
import styled, { keyframes } from 'styled-components';

import MEDIA from '../../mediaTemplates';
import Arrow from '../../images/scroll-arrow.svg';
import { Wrapper } from '../Common';
import Observer from '../Observer';
import Scroll from './Scroll';

const Gallery = styled.div`
  margin-top: 50px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  ${MEDIA.PHONE`
    margin-top: 30px;
  `};
`;

const ScrollIndicator = styled.div`
  margin-bottom: 50px;
  user-select: none;
  text-align: center;
  font-weight: 500;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-transform: uppercase;

  ${MEDIA.PHONE`
    margin-bottom: 30px;
  `};
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
`;

const AnimatedArrow = styled(Arrow)`
  display: block;
  margin: 4px auto 0;
  animation: ${bounce} 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
`;

export default ({ images }) => {
  return (
    <Gallery>
      <ScrollIndicator>
        Galerie
        <AnimatedArrow />
      </ScrollIndicator>

      <Observer>
        {({ hasBeenVisible }) => (
          <Wrapper>
            <Scroll isVisible={hasBeenVisible} images={images} />
          </Wrapper>
        )}
      </Observer>
    </Gallery>
  );
};
