import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { Element } from 'react-scroll';

import { COLORS } from '../../theme';
import MEDIA from '../../mediaTemplates';
import { H1, H3, Hr, Wrapper } from '../Common';
import { ButtonPrimary } from '../Buttons';
import Observer from '../Observer';
import FlowersSvg from '../../images/flowers.svg';

const Info = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s ease-in-out opacity;
`;

const InfoInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0;

  ${MEDIA.TABLET`
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 40px;
  `};

  ${MEDIA.PHONE`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `};
`;

const AnimatedH1 = styled(H1)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s 0.4s ease-in-out all;
`;

const AnimatedH3 = styled(H3)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s 0.2s ease-in-out all;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${MEDIA.PHONE`
    margin-bottom: 24px;
  `};

  hgroup {
    position: relative;
    z-index: 1;

    ${MEDIA.PHONE`
      width: 75%;
    `};
  }

  ${H1} {
    ${MEDIA.TABLET`
    font-size: 3.6rem;
  `};
  }
`;

const Flowers = styled(FlowersSvg)`
  opacity: 0.05;
  position: absolute;
  top: -40px;
  left: -40px;
  user-select: none;

  ${MEDIA.PHONE`
  top: -20px;
    `};
`;

const Right = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s 0.8s ease-in-out opacity;

  p {
    color: ${COLORS.TEXT_SECONDARY};
  }

  p + p {
    margin-top: 25px;
  }

  strong {
    font-weight: 500;
  }
`;

const AnimatedPrices = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s 0.6s ease-in-out opacity;
`;

const Prices = styled.div`
  width: 75%;
  padding: 16px 0 24px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;

  ${MEDIA.TABLET`
    width: 100%;
  `};

  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

const Label = styled.span`
  color: ${COLORS.TEXT_TERTIARY};
`;

const Value = styled.span`
  font-weight: 500;
`;

const Details = styled.div`
  width: 75%;
  font-size: 1.4rem;
  color: ${COLORS.TEXT_TERTIARY};
  margin-top: 32px;

  ${MEDIA.TABLET`
    width: 100%;
    margin-top: 20px;
  `};

  p + p {
    margin-top: 5px;
  }

  a {
    color: inherit;
  }
`;

const SignatureContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 80px 0;

  ${MEDIA.PHONE`
    grid-template-columns: 1fr;
    padding: 40px 0;
  `};
`;

const Signature = styled.div`
  padding: 0 40px;
  margin-left: ${({ left }) => (left ? 'auto' : 0)};
  margin-right: ${({ right }) => (right ? 'auto' : 0)};
  text-align: center;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s ease-in-out all;
  transition-delay: ${({ left }) => (left ? '0.2s' : '0.4s')};

  ${MEDIA.PHONE`
    padding: 20px 0;
    margin-left: 0;
    margin-right: 0;
  `};
`;

const InfoComponent = ({
  title,
  subtitle,
  priceSingle,
  priceDouble,
  pricingDetails,
  description,
  setFormVisible,
  data,
}) => (
  <>
    <Observer>
      {({ hasBeenVisible }) => (
        <Info isVisible={hasBeenVisible}>
          <Wrapper>
            <Element name="info">
              <Hr />
            </Element>
            <InfoInner>
              <Left>
                <hgroup>
                  <AnimatedH3 marginBottom isVisible={hasBeenVisible}>
                    {subtitle}
                  </AnimatedH3>
                  <AnimatedH1 as="h2" isVisible={hasBeenVisible}>
                    {title}
                  </AnimatedH1>
                </hgroup>
                <Flowers />

                <AnimatedPrices isVisible={hasBeenVisible}>
                  <Prices>
                    <div>
                      <Label>Einzelzimmer ab</Label>
                      <Value>{priceSingle}€</Value>
                    </div>

                    <div>
                      <Label>Doppelzimmer ab</Label>
                      <Value>{priceDouble}€</Value>
                    </div>

                    <Hr marginTop={16} marginBottom={24} />

                    <ButtonPrimary as={Link} to="/rooms" full>
                      Zimmeranfrage
                    </ButtonPrimary>
                  </Prices>

                  <Details
                    dangerouslySetInnerHTML={{ __html: pricingDetails }}
                  />
                </AnimatedPrices>
              </Left>
              <Right
                isVisible={hasBeenVisible}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </InfoInner>

            <Hr marginBottom={0} />
          </Wrapper>
        </Info>
      )}
    </Observer>
    <Observer rootMargin="-50px">
      {({ hasBeenVisible }) => (
        <SignatureContainer>
          <Signature left isVisible={hasBeenVisible}>
            <GatsbyImage fixed={data.signature1.childImageSharp.fixed} />
            <H3 as="div">Ute Schultz</H3>
          </Signature>
          <Signature right isVisible={hasBeenVisible}>
            <GatsbyImage fixed={data.signature2.childImageSharp.fixed} />
            <H3 as="div">Hubertus Schultz</H3>
          </Signature>
        </SignatureContainer>
      )}
    </Observer>
  </>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        signature1: file(relativePath: { regex: "/signature-1/" }) {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        signature2: file(relativePath: { regex: "/signature-2/" }) {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <InfoComponent data={data} {...props} />}
  />
);
