import React from 'react';
import { graphql } from 'gatsby';

import Head from '../components/Head';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Gallery from '../components/Gallery';
import Info from '../components/Info';
import Footer from '../components/Footer';

import GlobalStyle from '../GlobalStyle';
import Teaser360 from '../components/Teaser360';
import Bikes from '../components/Bikes';

export default ({ data }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Head pageTitle="Home" />
      <Header />
      <Hero
        image={data.prismicHomepage.data.hero}
        title={data.prismicHomepage.data.title.text}
        subtitle={data.prismicHomepage.data.subtitle.text}
        intro={data.prismicHomepage.data.intro.html}
        email={data.prismicHomepage.data.email.text}
      />
      <Gallery images={data.prismicHomepage.data.gallery} />
      <Teaser360 />
      <Bikes image={data.prismicHomepage.data.feature_image} />
      <Info
        title={data.prismicHomepage.data.info_title.text}
        subtitle={data.prismicHomepage.data.info_subtitle.text}
        priceSingle={data.prismicHomepage.data.price_single}
        priceDouble={data.prismicHomepage.data.price_double}
        pricingDetails={data.prismicHomepage.data.details.html}
        description={data.prismicHomepage.data.description.html}
      />
      <Footer
        image={data.prismicHomepage.data.outro}
        address={data.prismicHomepage.data.address.html}
      />
      <GlobalStyle />
    </div>
  );
};

export const pageQuery = graphql`
  {
    prismicHomepage {
      data {
        gallery {
          image {
            url
            localFile {
              name
              childImageSharp {
                fluid(maxHeight: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        price_single
        price_double
        description {
          html
        }
        details {
          html
        }
        feature_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 540, cropFocus: NORTH) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 700, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        info_subtitle {
          text
        }
        info_title {
          text
        }
        intro {
          html
        }
        address {
          html
        }
        outro {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        subtitle {
          text
        }
        title {
          text
        }
        email {
          text
        }
      }
    }
  }
`;
