import React, { Component } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import HorizontalScroll from '../HorizontalScroll';
import MEDIA from '../../mediaTemplates';

class CareersImages extends Component {
  state = {
    activeIndex: 0,
    disabled: false,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keydown', this.handleKeyPress);
    }
  }

  handleKeyPress = ({ keyCode }) => {
    const leftKeyCode = 37;
    const rightKeyCode = 39;

    if (keyCode === leftKeyCode) {
      this.handlePrevClick();
    }
    if (keyCode === rightKeyCode) {
      this.handleNextClick();
    }
  };

  handleNextClick = () => {
    if (
      this.state.activeIndex === this.props.images.length / 2 - 1 ||
      this.state.disabled
    ) {
      return;
    }

    this.setState({
      activeIndex: this.state.activeIndex + 1,
      disabled: true,
    });

    setTimeout(() => {
      this.setState({ disabled: false });
    }, 800);
  };

  handlePrevClick = () => {
    if (this.state.activeIndex === 0 || this.state.disabled) {
      return;
    }

    this.setState({
      activeIndex: this.state.activeIndex - 1,
      disabled: true,
    });

    setTimeout(() => {
      this.setState({ disabled: false });
    }, 800);
  };

  render() {
    const { activeIndex } = this.state;
    const offset = activeIndex * 100 * -1;

    return (
      <>
        <CareersImagesContainer isVisible={this.props.isVisible}>
          <GalleryContainer style={{ transform: `translateX(${offset}rem)` }}>
            {this.props.images.map(({ image }, index) => (
              <ImageContainer
                key={image.localFile.childImageSharp.fluid.src}
                index={index}
                activeIndex={activeIndex}
                inView={this.state.inView}
                viewed={this.state.viewed}
                style={{ left: `${index * 50}rem` }}
              >
                <Img fluid={image.localFile.childImageSharp.fluid} />
              </ImageContainer>
            ))}
          </GalleryContainer>
          <GalleryControl
            disabled={activeIndex === 0}
            onClick={this.handlePrevClick}
            data-a11y="false"
            left
            aria-label="Vorherige Seite"
          >
            <ChevronLeft />
          </GalleryControl>
          <GalleryControl
            disabled={activeIndex === this.props.images.length / 2 - 1}
            onClick={this.handleNextClick}
            data-a11y="false"
            right
            aria-label="Nächste Seite"
          >
            <ChevronRight />
          </GalleryControl>
        </CareersImagesContainer>
        <CareersImagesContainerMobile isVisible={this.props.isVisible}>
          <HorizontalScroll
            narrow
            list={this.props.images}
            name="image"
            render={({ image }) => (
              <ImageContainerMobile>
                <Img fluid={image.image.localFile.childImageSharp.fluid} />
              </ImageContainerMobile>
            )}
          />
        </CareersImagesContainerMobile>
      </>
    );
  }
}

export default CareersImages;

const CareersImagesContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding-left: 9rem;

  opacity: ${p => (p.isVisible ? 1 : 0)};
  transition: 0.4s ease-in-out opacity;

  .gatsby-image-wrapper > img {
    filter: blur(20px);
  }

  ${MEDIA.TABLET`
    padding-left: 0;
  `};

  ${MEDIA.PHONE`
    display: none;
  `};
`;

const CareersImagesContainerMobile = styled.div`
  display: none;

  opacity: ${p => (p.isVisible ? 1 : 0)};
  transition: 0.4s ease-in-out all;

  ${MEDIA.PHONE`
    display: block;
    width: 100%;
    max-height: 215px;
  `};
`;

const GalleryContainer = styled.div`
  position: relative;
  height: 34rem;
  transition: transform 0.8s cubic-bezier(0.7, 0, 0.2, 1);

  ${MEDIA.TABLET`
    height: 30rem;
  `};
`;

/**
 * 0 ==> 0 1
 * 1 ==> 2 3
 * 2 ==> 4 5
 * 3 ==> 6 7
 *
 */
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10rem;

  position: absolute;
  left: 0;
  width: 48rem;
  height: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  z-index: -1;

  filter: ${p =>
    p.activeIndex * 2 === p.index || p.index === p.activeIndex * 2 + 1
      ? `grayscale(0%)`
      : `grayscale(80%)`};

  opacity: ${p =>
    p.activeIndex * 2 === p.index || p.index === p.activeIndex * 2 + 1
      ? 1
      : 0.2};
  transition: opacity 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53),
    filter 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);

  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  ${MEDIA.PHONE`
    width: 28rem;
  `};
`;

const ImageContainerMobile = styled.div`
  overflow: hidden;
  width: 34rem;
  height: 34rem;

  ${MEDIA.TABLET`
    width: 100%;
  `};
`;

const GalleryControl = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  z-index: 100;

  ${p => {
    if (p.left) {
      return `left: 0;`;
    }

    if (p.right) {
      return `right: 0;`;
    }
  }};
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);
  cursor: ${p => (p.disabled ? 'initial' : 'pointer')};

  opacity: ${p => (p.disabled ? 0.25 : 1)};
  transition: opacity 600ms cubic-bezier(0.7, 0, 0.2, 1);

  &:active,
  &:focus {
    outline: none;
  }
`;

const ChevronRight = ({ fill = '#090a0c' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M10.667 9.88l1.88-1.88 8 8-8 8-1.88-1.88 6.107-6.12z"
    />
    <path fill="none" d="M0 0h32v32h-32z" />
  </svg>
);

const ChevronLeft = ({ fill = '#090a0c' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M20.547 9.88l-6.107 6.12 6.107 6.12-1.88 1.88-8-8 8-8z"
    />
    <path fill="none" d="M0 0h32v32h-32z" />
  </svg>
);
