import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { Element } from 'react-scroll';

import { COLORS } from '../../theme';
import MEDIA from '../../mediaTemplates';
import { H1, H3, Hr, Wrapper } from '../Common';
import { ButtonPrimary } from '../Buttons';
import Observer from '../Observer';
import BikeSvg from '../../images/bike.svg';

const Info = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s ease-in-out opacity;
`;

const InfoInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0px;

  ${MEDIA.TABLET`
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
  `};

  ${MEDIA.PHONE`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `};
`;

const AnimatedH1 = styled(H1)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s 0.4s ease-in-out all;
`;

const AnimatedH3 = styled(H3)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s 0.2s ease-in-out all;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-right: 50px;

  ${MEDIA.TABLET`
    padding-right: 0;
  `};

  ${MEDIA.PHONE`
      margin-bottom: 24px;
    `};
  }
`;

const Right = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s 0.8s ease-in-out opacity;
  position: relative;

  p {
    color: ${COLORS.TEXT_SECONDARY};
  }


  hgroup {
    position: relative;
    z-index: 1;
  }

  ${H1} {
    ${MEDIA.TABLET`
    font-size: 3.6rem;
  `};
`;

const Image = styled(GatsbyImage)`
  height: 100%;
`;

const Bike = styled(BikeSvg)`
  opacity: 0.05;
  position: absolute;
  bottom: -60px;
  right: 40px;
  user-select: none;
  width: 350px;
  height: 350px;
  z-index: -1;

  ${MEDIA.TABLET`
  bottom: -60px;
  right: -40px;
  width: 300px;
  height: 300px;
  `};

  ${MEDIA.PHONE`
  bottom: -60px;
  right: 20px;
  width: 250px;
  height: 250px;
    `};
`;

export default ({ title, subtitle, image, data }) => (
  <>
    <Observer>
      {({ hasBeenVisible }) => (
        <Info isVisible={hasBeenVisible}>
          <Wrapper>
            <Hr />
            <InfoInner>
              <Left>
                <Image fluid={image.localFile.childImageSharp.fluid} />
              </Left>
              <Right isVisible={hasBeenVisible}>
                <hgroup>
                  <AnimatedH3 marginBottom isVisible={hasBeenVisible}>
                    Abschließbarer Fahrradraum
                  </AnimatedH3>
                  <AnimatedH1 as="h2" isVisible={hasBeenVisible}>
                    Perfekt für Fahrradreisende
                  </AnimatedH1>
                </hgroup>
                <p>
                  Schließen Sie über Nacht ihre Fahrräder bequem in unserem
                  sicheren Fahrradraum ab. Darin sind auch ausreichend
                  Steckdosen vorhanden, um E-Bikes zu laden. Im benachbarten
                  Tourismusbüro bekommen Sie gute Tipps für die anstehenden
                  Touren.
                </p>
                <Bike />
              </Right>
            </InfoInner>
          </Wrapper>
        </Info>
      )}
    </Observer>
  </>
);
