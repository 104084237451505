import React from 'react';
import styled, { keyframes } from 'styled-components';

import MEDIA from '../../mediaTemplates';
import { Hr, Wrapper } from '../Common';
import { ButtonPrimary } from '../Buttons';
import Observer from '../Observer';

const Teaser360 = styled.div`
  margin-top: 50px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;

  ${MEDIA.PHONE`
    margin-top: 30px;
  `};
`;

const ButtonConainer = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s ease-in-out opacity;
`;

export default ({ images }) => {
  return (
    <Teaser360>
      <Observer>
        {({ hasBeenVisible }) => (
          <Wrapper>
            <ButtonConainer isVisible={hasBeenVisible}>
              <ButtonPrimary
                as={`a`}
                href="https://my.matterport.com/show/?m=evGU7ezfamZ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M12 7C6.48 7 2 9.24 2 12c0 2.24 2.94 4.13 7 4.77V20l4-4-4-4v2.73c-3.15-.56-5-1.9-5-2.73 0-1.06 3.04-3 8-3s8 1.94 8 3c0 .73-1.46 1.89-4 2.53v2.05c3.53-.77 6-2.53 6-4.58 0-2.76-4.48-5-10-5z"></path>
                </svg>
                360° Rundgang erkunden
              </ButtonPrimary>
            </ButtonConainer>
          </Wrapper>
        )}
      </Observer>
    </Teaser360>
  );
};
