import React from 'react';
import styled, { keyframes } from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { COLORS } from '../../theme';
import MEDIA from '../../mediaTemplates';
import { ButtonPrimary, ButtonSecondary } from '../Buttons';
import { H1, H3, Wrapper } from '../Common';
import Observer from '../Observer';

const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s ease-in-out opacity;

  ${MEDIA.TABLET`
    grid-template-columns: 1fr;
  `};
`;

const H3Animated = styled(H3)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s 0.2s ease-in-out all;
`;

const H1WithConstraints = styled(H1)`
  max-width: 80%;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s 0.4s ease-in-out all;

  ${MEDIA.PHONE`
    max-width: 100%;
  `};
`;

const Left = styled.div`
  min-height: 600px;
  height: 100%;
  background-color: ${COLORS.BACKGROUND_DIMMED};

  ${MEDIA.TABLET`
    min-height: 0;
    height: 33vh;
  `};
`;

const Image = styled(GatsbyImage)`
  height: 100%;
`;

const Right = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLORS.BACKGROUND_DIMMED};

  ${MEDIA.PHONE`
    padding: 20px;
  `};
`;

const AnimatedIntro = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? `translateY(0)` : `translateY(-10%)`};
  transition: 0.4s 0.6s ease-in-out all;
`;

const Intro = styled.div`
  margin-bottom: 24px;
  color: ${COLORS.TEXT_SECONDARY};

  ${MEDIA.PHONE`
    margin-bottom: 16px;
  `};
`;

const ButtonGroup = styled.div`
  ${MEDIA.PHONE`
    text-align: center;
  `};
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Badge = styled.div`
  width: 115px;
  position: absolute;
  right: 40px;
  top: -57px;
  animation: ${rotate} 50s linear infinite;
  z-index: 1;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: 0.4s 1s ease-in-out opacity;

  ${MEDIA.TABLET`
    width: 90px;
    top: -45px;
  `};

  ${MEDIA.PHONE`
    width: 60px;
    top: -30px;
    display: none;
  `};
`;

const Hero = ({
  data,
  image,
  title,
  subtitle,
  intro,
  email,
  setFormVisible,
}) => (
  <Observer>
    {({ hasBeenVisible }) => (
      <Wrapper>
        <Badge isVisible={hasBeenVisible}>
          <Image fluid={data.file.childImageSharp.fluid} />
        </Badge>
        <HeroContainer isVisible={hasBeenVisible}>
          <Right>
            <hgroup>
              <H3Animated marginBottom isVisible={hasBeenVisible}>
                {subtitle}
              </H3Animated>
              <H1WithConstraints isVisible={hasBeenVisible}>
                {title}
              </H1WithConstraints>
            </hgroup>

            <AnimatedIntro isVisible={hasBeenVisible}>
              <Intro dangerouslySetInnerHTML={{ __html: intro }} />

              <ButtonGroup>
                <ButtonPrimary as={Link} to="/rooms" marginRight>
                  Zimmeranfrage
                </ButtonPrimary>
                <ButtonSecondary href={`mailto:${email}?subject=Zimmeranfrage`}>
                  Oder per Email
                </ButtonSecondary>
              </ButtonGroup>
            </AnimatedIntro>
          </Right>
          <Left>
            <Image fluid={image.localFile.childImageSharp.fluid} />
          </Left>
        </HeroContainer>
      </Wrapper>
    )}
  </Observer>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/badge/" }) {
          childImageSharp {
            fluid(maxWidth: 115) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <Hero data={data} {...props} />}
  />
);
